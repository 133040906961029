import { Component, OnInit } from '@angular/core';

import { Contrato } from '../contrato';
import { ContratoService } from '../contrato.service';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss']
})
export class ContratosComponent implements OnInit {
  contratos: Contrato[];

  constructor(private contratoService: ContratoService) { }

  ngOnInit() {
  }

  getContratos(): void {
    this.contratoService.getContratos()
      .subscribe(contratos => this.contratos = contratos);
  }

  getContrato(id: number): void {
    this.contratoService.getContrato(id)
      .subscribe(contratos => {
        this.contratos = [];
        this.contratos.push(contratos);
      });
  }

  add(nombre: string): void {
    nombre = nombre.trim();
    if (!nombre) { return; }
    this.contratoService.addContrato({ nombre } as Contrato)
      .subscribe(contrato => {
        this.contratos.push(contrato);
      });
  }

  delete(contrato: Contrato): void {
    this.contratos = this.contratos.filter(h => h !== contrato);
    this.contratoService.deleteContrato(contrato).subscribe();
  }

}