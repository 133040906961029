import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Agente } from './Agente';
import { MessageService } from './message.service';


@Injectable({ providedIn: 'root' })
export class AgenteService {

  private agentesUrl = 'https://back.ibercostaenergia.com/api/agentes';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService
    ) { }

  /** GET agentes from the server */
  getAgentes(): Observable<Agente[]> {
    return this.http.get<Agente[]>(this.agentesUrl)
      .pipe(
        tap(_ => this.log('fetched agentes')),
        catchError(this.handleError<Agente[]>('getAgentes', []))
      );
  }

  /** GET agente by id. Return `undefined` when id not found */
  getAgenteNo404<Data>(id: number): Observable<Agente> {
    const url = `${this.agentesUrl}/?id=${id}`;
    return this.http.get<Agente[]>(url)
      .pipe(
        map(agentes => agentes[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} agente id=${id}`);
        }),
        catchError(this.handleError<Agente>(`getAgentes id=${id}`))
      );
  }

  /** GET agente by id. Will 404 if id not found */
  getAgente(id: number): Observable<Agente> {
    const url = `${this.agentesUrl}/${id}`;
    return this.http.get<Agente>(url).pipe(
      tap(_ => this.log(`fetched agente id=${id}`)),
      catchError(this.handleError<Agente>(`getAgente id=${id}`))
    );
  }

  /* GET agentes whose name contains search term */
  searchAgentes(term: string): Observable<Agente[]> {
    if (!term.trim()) {
      // if not search term, return empty agente array.
      return of([]);
    }
    return this.http.get<Agente[]>(`${this.agentesUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
         this.log(`found agentes matching "${term}"`) :
         this.log(`no agentes matching "${term}"`)),
      catchError(this.handleError<Agente[]>('searchAgentes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new agente to the server */
  addAgente(agente: Agente): Observable<any> {
    return this.http.post<Agente>(`${this.agentesUrl}/create`, agente, this.httpOptions).pipe(
      tap((newAgente: Agente) => this.log(`added agente w/ id=${newAgente.id}`)),
      catchError(this.handleError<Agente>('addAgente'))
    );
  }

  /** DELETE: delete the agente from the server */
  deleteAgente(agente: Agente | number): Observable<Agente> {
    const id = typeof agente === 'number' ? agente : agente.id;
    const url = `${this.agentesUrl}/${id}`;

    return this.http.delete<Agente>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted agente id=${id}`)),
      catchError(this.handleError<Agente>('deleteAgente'))
    );
  }

  /** POST: update the agente on the server */
  updateAgente(agente: Agente): Observable<any> {
    const url = `${this.agentesUrl}/${agente.id}`;
    return this.http.post(url, agente, this.httpOptions).pipe(
      tap(_ => this.log(`updated agente id=${agente.id}`)),
      catchError(this.handleError<any>('updateAgente'))
    );
  }

  /** POST: update the agente activo field on the server */
  cambiarAgenteActivo(agente: number): Observable<any> {
    const url = `${this.agentesUrl}/cambiarActivo/${agente}`;
    return this.http.post(url, null, this.httpOptions).pipe(
      tap(_ => this.log(`updated agente activo field id=${agente}`)),
      catchError(this.handleError<any>('updateAgente'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a AgenteService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`AgenteService: ${message}`);
  }
}