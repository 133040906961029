import { Component, OnInit } from "@angular/core";
import { AccountService } from '../../auth.service';
import { Router } from '@angular/router';


declare interface RouteInfo {
  path: string;
  title: string;
  rtlTitle: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  /* {
    path: "/dashboard",
    title: "Dashboard",
    rtlTitle: "لوحة القيادة",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/icons",
    title: "Icons",
    rtlTitle: "الرموز",
    icon: "icon-atom",
    class: ""
  },
  {
    path: "/ranking",
    title: "ranking",
    rtlTitle: "خرائط",
    icon: "icon-bullet-list-67",
    class: "" 
  },*/
  {
    path: "/contratos",
    title: "Contratos",
    rtlTitle: "خرائط",
    icon: "icon-notes",
    class: ""
  },
  {
    path: "/agentes",
    title: "Agentes",
    rtlTitle: "قائمة الجدول",
    icon: "icon-single-02",
    class: ""
  },
  {
    path: "/administrador",
    title: "Administradores",
    rtlTitle: "قائمة الجدول",
    icon: "icon-badge",
    class: ""
  },
  {
    path: "/agencias",
    title: "Agencias",
    rtlTitle: "قائمة الجدول",
    icon: "icon-bank",
    class: ""
  },
  /* {
    path: "/notifications",
    title: "Notifications",
    rtlTitle: "إخطارات",
    icon: "icon-bell-55",
    class: ""
  },
  {
    path: "/user",
    title: "User Profile",
    rtlTitle: "ملف تعريفي للمستخدم",
    icon: "icon-single-02",
    class: ""
  },
  {
    path: "/typography",
    title: "Typography",
    rtlTitle: "طباعة",
    icon: "icon-align-center",
    class: ""
  },
  {
    path: "/rtl",
    title: "RTL Support",
    rtlTitle: "ار تي ال",
    icon: "icon-world",
    class: ""
  } */
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private accountService: AccountService,
    private router: Router) { }

  ngOnInit() {
    if (!this.accountService.userValue) {
      this.router.navigate(['/login']);
    } else {
      const user = this.accountService.userValue.user;
      if ((user.tipo == "ADMIN")) {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      } else {
        this.menuItems = ROUTES.filter(menuItem => { if ((menuItem.title !== "Agencias") && (menuItem.title !== "Administradores")) return menuItem });
      }
    }
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
