import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Contrato } from './Contrato';
import { MessageService } from './message.service';


@Injectable({ providedIn: 'root' })
export class ContratoService {

  private contratosUrl = 'https://back.ibercostaenergia.com/api/contratos';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService
    ) { }

  /** GET contratos from the server */
  getContratos(): Observable<Contrato[]> {
    return this.http.get<Contrato[]>(this.contratosUrl)
      .pipe(
        tap(_ => this.log('fetched contratos')),
        catchError(this.handleError<Contrato[]>('getContratos', []))
      );
  }


  /** POST files */
  uploadFiles(formData, contrato): Observable<any[]> {
    return this.http.post<any>(this.contratosUrl + "/contratosFiles/" + contrato, formData).pipe(
      tap((files: any) => this.log(`added files`)),
      catchError(this.handleError<Contrato>('addContrato'))
    );
  }

  /** GET contratos by id. Return `undefined` when id not found */
  getContratoNo404<Data>(id: number): Observable<Contrato> {
    const url = `${this.contratosUrl}/?id=${id}`;
    return this.http.get<Contrato[]>(url)
      .pipe(
        map(contratos => contratos[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} contratos id=${id}`);
        }),
        catchError(this.handleError<Contrato>(`getContratos id=${id}`))
      );
  }

  /** GET contratos by id. Will 404 if id not found */
  getContrato(id: number): Observable<Contrato> {
    const url = `${this.contratosUrl}/${id}`;
    return this.http.get<Contrato>(url).pipe(
      tap(_ => this.log(`fetched contratos id=${id}`)),
      catchError(this.handleError<Contrato>(`getContrato id=${id}`))
    );
  }

  /* GET contratos whose name contains search term */
  searchContratos(term: string): Observable<Contrato[]> {
    if (!term.trim()) {
      // if not search term, return empty contratos array.
      return of([]);
    }
    return this.http.get<Contrato[]>(`${this.contratosUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
         this.log(`found contratos matching "${term}"`) :
         this.log(`no contratos matching "${term}"`)),
      catchError(this.handleError<Contrato[]>('searchContratos', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new contratos to the server */
  addContrato(contratos: any): Observable<any> {
    return this.http.post<Contrato>(`${this.contratosUrl}/create`, contratos, this.httpOptions).pipe(
      tap((newContrato: Contrato) => this.log(`added contratos w/ id=${newContrato.id}`)),
      catchError(this.handleError<Contrato>('addContrato'))
    );
  }

  /** DELETE: delete the contratos from the server */
  deleteContrato(contratos: Contrato | number): Observable<Contrato> {
    const id = typeof contratos === 'number' ? contratos : contratos.id;
    const url = `${this.contratosUrl}/${id}`;

    return this.http.delete<Contrato>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted contratos id=${id}`)),
      catchError(this.handleError<Contrato>('deleteContrato'))
    );
  }

  /** POST: update the contratos on the server */
  updateContrato(contratos: Contrato): Observable<any> {
    const url = `${this.contratosUrl}/${contratos.id}`;
    return this.http.post(url, contratos, this.httpOptions).pipe(
      tap(_ => this.log(`updated contratos id=${contratos.id}`)),
      catchError(this.handleError<any>('updateContrato'))
    );
  }

  /** POST: update the contratos historialCambios on the server */
  updateHistorialCambios(contratos: number, estado: string, observaciones: string): Observable<any> {
    const url = `${this.contratosUrl}/actualizarEstado/${contratos}`;
    return this.http.post(url, {estado, observaciones}, this.httpOptions).pipe(
      tap(_ => this.log(`updated contratos activo field id=${contratos}`)),
      catchError(this.handleError<any>('updateContrato'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ContratoService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`ContratoService: ${message}`);
  }
}