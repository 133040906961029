import { Component, OnInit } from '@angular/core';

import { Agente } from '../agente';
import { AgenteService } from '../agente.service';
import { AccountService } from '../auth.service';


@Component({
  selector: 'app-agentes',
  templateUrl: './agentes.component.html',
  styleUrls: ['./agentes.component.scss']
})
export class AgentesComponent implements OnInit {
  agentes: Agente[];

  constructor(private agenteService: AgenteService,
    private accountService: AccountService) { }

  ngOnInit() {
  }

  getAgentes(): void {    
    this.agenteService.getAgentes()
      .subscribe(agentes => this.agentes = agentes);
  }

  getAgente(id: number): void {
    this.agenteService.getAgente(id)
      .subscribe(agentes => {
        this.agentes = [];
        this.agentes.push(agentes);
      });
  }

  cambiarAgenteActivo(id: number): void {
    this.agenteService.cambiarAgenteActivo(id)
      .subscribe(res => {
        //console.log(res);
      });
  }

  add(nombre: string): void {
    nombre = nombre.trim();
    if (!nombre) { return; }
    this.agenteService.addAgente({ nombre } as Agente)
      .subscribe(agente => {
        this.agentes.push(agente);
      });
  }

  delete(agente: Agente): void {
    this.agentes = this.agentes.filter(h => h !== agente);
    this.agenteService.deleteAgente(agente).subscribe();
  }

}